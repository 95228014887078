import { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Box, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { MuiFileInput } from 'mui-file-input';
import { useTranslation } from 'react-i18next';
import { status } from '../../utils';

const TaskForm = ({ taskName, onStarting, currentStatus }) => {
  const [file, setFile] = useState(null);

  const { t } = useTranslation();

  const handleStart = () => {
    if (!file) {
      toast.warning(t('no file selected'), {
        position: 'top-right',
      });
      return;
    }
    onStarting(file);
  };

  const handleChange = newFile => {
    setFile(newFile);
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        '& .MuiTextField-root': { m: '1rem 0', width: '100%' },
      }}
    >
      <TextField
        id="AddTaskTextInput-1"
        name="taskName"
        label={t('name')}
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={taskName}
        disabled
      />
      <MuiFileInput
        id="AddTaskFileInput-1"
        inputProps={{
          className: 'MuiFileInput',
        }}
        hideSizeText
        getInputText={value => {
          return value?.name ?? '';
        }}
        value={file}
        label={t('insert a file')}
        onChange={handleChange}
      />
      <LoadingButton
        variant="contained"
        type="button"
        color="secondary"
        sx={{ display: 'flex', m: '0.5rem auto' }}
        onClick={handleStart}
        loading={currentStatus === status.PENDING1}
      >
        {t('start processing')}
      </LoadingButton>
    </Box>
  );
};

TaskForm.propTypes = {
  taskName: PropTypes.string,
  onStarting: PropTypes.func.isRequired,
  currentStatus: PropTypes.string.isRequired,
};

export default TaskForm;
