import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const StatusTable = ({ data }) => {
  const { t } = useTranslation();

  const createCell = data => {
    if (typeof data === 'object') {
      return Object.keys(data).map(key => (
        <p key={key}>
          {key}: {data[key]}
        </p>
      ));
    } else {
      return data;
    }
  };

  return (
    <TableContainer>
      <Table aria-label="task status table">
        <TableBody>
          {Object.keys(data).map(key => {
            return (
              <TableRow key={key}>
                <TableCell>{t(key)}</TableCell>
                <TableCell>{createCell(data[key])}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

StatusTable.propTypes = {
  data: PropTypes.object,
};

export default StatusTable;
