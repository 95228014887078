import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@mui/material';

const SubviewContainer = ({ title, children }) => {
  return (
    <Box pt={3} px={20}>
      {title && <Typography variant="h1">{title}</Typography>}

      <Box pt={3}>
        <Paper elevation={1}>{children}</Paper>
      </Box>
    </Box>
  );
};

SubviewContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SubviewContainer;
