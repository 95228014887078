import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';

const INITIAL_STATE = {
  username: '',
  password: '',
};

const LoginForm = ({ onAuthSubmit, isLoading }) => {
  const [username, setUsername] = useState(INITIAL_STATE.username);
  const [password, setPassword] = useState(INITIAL_STATE.password);

  const { t } = useTranslation();

  const handleInputChange = e => {
    const { name, value } = e.currentTarget;

    switch (name) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const data = { username, password };

    onAuthSubmit(data);
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        '& .MuiTextField-root': { m: '1rem 0', width: '100%' },
      }}
    >
      <TextField
        id="LoginTextField-1"
        name="username"
        value={username}
        required
        label={t('login')}
        autoFocus
        variant="standard"
        onChange={handleInputChange}
      />
      <TextField
        id="LoginTextField-2"
        name="password"
        value={password}
        required
        label={t('password')}
        type="password"
        variant="standard"
        onChange={handleInputChange}
      />
      <LoadingButton
        variant="contained"
        type="submit"
        color="secondary"
        sx={{ display: 'flex', m: '1rem auto 0' }}
        loading={isLoading}
      >
        {t('enter')}
      </LoadingButton>
    </Box>
  );
};

LoginForm.propTypes = {
  onAuthSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default LoginForm;
