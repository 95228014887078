import { Link } from 'react-router-dom';
import { Box, AppBar, Toolbar, Container, Typography } from '@mui/material';
import logo from '../../assets/ML_logo_red.png';

const Header = () => {
  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              width: 150,
              position: 'relative',
            }}
          >
            <Typography noWrap component={Link} to="/">
              <img src={logo} alt="Logo" />
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
