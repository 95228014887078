import axios from '../axios';

export const getAuthenticated = async data => {
  try {
    const res = await axios.post('v1/login/access-token', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const createTask = async data => {
  try {
    const res = await axios.post('v1/tasks', data);

    return res;
  } catch (error) {
    throw error;
  }
};

export const uploadTaskFile = async (taskId, data) => {
  try {
    const res = await axios.postForm(`v1/tasks/${taskId}/file`, {
      file: data,
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export const startAssessment = async taskId => {
  try {
    const res = await axios.post(`v1/tasks/${taskId}/start-assessment`);

    return res;
  } catch (error) {
    throw error;
  }
};

export const fetchTaskStatus = async taskId => {
  try {
    const res = await axios.post(`v1/tasks/${taskId}/status`);

    return res;
  } catch (error) {
    throw error;
  }
};

export const getTaskResult = async taskId => {
  try {
    const res = await axios.get(`v1/tasks/${taskId}/result`, {
      responseType: 'blob',
    });

    return res;
  } catch (error) {
    throw error;
  }
};
