export const auth = {
  TOKEN: 'tokenOvert',
  USER_ID: 'userIdOvert',
};

export const status = {
  IDLE: 'idle',
  PENDING1: 'pending1',
  PENDING2: 'pending2',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',

  SUCCESS: 'SUCCESS',
};

export const statusCodes = {
  HTTP_200_OK: 200,
  HTTP_201_CREATED: 201,
};
