import { useLocation, Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ auth, redirectTo = '/login', children }) => {
  const location = useLocation();

  return auth ? (
    children ? (
      children
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
  redirectTo: PropTypes.string,
  auth: PropTypes.bool.isRequired,
};

export default PrivateRoute;
