import { useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LoginForm from '../components/LoginForm';
import { auth, statusCodes, createNotification } from '../utils';
import logo from '../assets/ML_logo_white.png';
import { getAuthenticated } from '../services/api_requests';
import './styles.css';

const LoginView = ({ onAuth }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmitAuth = async data => {
    setIsLoading(true);
    try {
      const response = await getAuthenticated(data);

      if (response?.status === statusCodes.HTTP_200_OK) {
        localStorage.setItem(auth.TOKEN, response.data.access_token);
        localStorage.setItem(auth.USER_ID, response.data.user_id);
        onAuth(true);
      }
    } catch (err) {
      createNotification(t('common error'), 'error');
      console.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main>
      <Box className="login-bg">
        <Box
          sx={{
            width: 150,
            position: 'relative',
            top: '1.1rem',
            left: '1.5rem',
          }}
        >
          <img src={logo} alt="Logo" />
        </Box>

        <Box
          pt={12}
          sx={{
            width: 450,
            m: '0 auto',
          }}
        >
          <Paper elevation={12}>
            <Box sx={{ padding: '1rem 1.5rem' }}>
              <Typography
                variant="h1"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'var(--font-weight-medium)',
                }}
              >
                {t('login to app')}
              </Typography>
              <LoginForm
                onAuthSubmit={handleSubmitAuth}
                isLoading={isLoading}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </main>
  );
};

LoginView.propTypes = {
  onAuth: PropTypes.func.isRequired,
};

export default LoginView;
