import { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Layout from './components/Layout';
import Header from './components/Header';
import Footer from './components/Footer';
import PublicRoute from './components/routes/PublicRoute';
import PrivateRoute from './components/routes/PrivateRoute';
import TaskView from './views/TaskView';
import LoginView from './views/LoginView';
import AddTaskView from './views/AddTaskView';
import { auth } from './utils';
import axios from './axios';

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const token = localStorage.getItem(auth.TOKEN);
  const userId = localStorage.getItem(auth.USER_ID);

  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  useEffect(() => {
    if (token && userId) {
      setIsAuth(true);
    }
    setIsChecking(false);
  }, [token, userId]);

  return (
    <>
      {!isChecking && (
        <>
          {isAuth && <Header />}
          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute auth={isAuth}>
                  <LoginView onAuth={setIsAuth} />
                </PublicRoute>
              }
            />
            <Route
              path="/tasks"
              element={
                <PrivateRoute auth={isAuth}>
                  <Layout />
                </PrivateRoute>
              }
            >
              <Route index element={<AddTaskView userId={userId} />} />
              <Route path=":taskId" element={<TaskView />} />
            </Route>
            <Route path="*" element={<Navigate to="/tasks" />} />
          </Routes>
          <Footer />
        </>
      )}
      <ToastContainer autoClose={4000} />
    </>
  );
}

export default App;
