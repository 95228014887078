import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import SubviewContainer from '../components/SubviewContainer';
import TaskForm from '../components/TaskForm';
import StatusTable from '../components/StatusTable';
import {
  uploadTaskFile,
  startAssessment,
  fetchTaskStatus,
  getTaskResult,
} from '../services/api_requests';
import { status, saveFile, statusCodes, createNotification } from '../utils';

const TaskView = () => {
  const [currentStatus, setCurrentStatus] = useState(status.IDLE);
  const [statusData, setStatusData] = useState(null);

  const { t } = useTranslation();

  const { state } = useLocation();
  const params = useParams();

  const taskName = state?.name ?? '';
  const taskId = Number(params.taskId);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetchTaskStatus(taskId);

        if (response) {
          setStatusData(response.data);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchStatus();
    const interval = setInterval(fetchStatus, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [taskId]);

  useEffect(() => {
    if (currentStatus === status.REJECTED) {
      createNotification(t('common error'), 'error');
    }
  }, [currentStatus, t]);

  const handleStartAssessment = async data => {
    setCurrentStatus(status.PENDING1);

    try {
      const uploadResponse = await uploadTaskFile(taskId, data);

      if (uploadResponse.status === statusCodes.HTTP_201_CREATED) {
        const startResponse = await startAssessment(taskId);
        if (startResponse.status === statusCodes.HTTP_200_OK) {
          setCurrentStatus(status.RESOLVED);
          createNotification(t('task processing'), 'success');
        }
      }
    } catch (error) {
      setCurrentStatus(status.REJECTED);
      console.log(error.message);
    }
  };

  const handleDownloadResultFile = async () => {
    setCurrentStatus(status.PENDING2);

    try {
      const data = await getTaskResult(taskId);

      if (data) {
        saveFile(data.data, taskName || `${taskId}`);
        setCurrentStatus(status.RESOLVED);
      }
    } catch (error) {
      setCurrentStatus(status.REJECTED);
      console.log(error.message);
    }
  };

  return (
    <SubviewContainer>
      <Box sx={{ padding: '1rem 2rem 2rem' }}>
        <TaskForm
          taskName={taskName}
          currentStatus={currentStatus}
          onStarting={handleStartAssessment}
        />
        {statusData && (
          <Box mt="1rem">
            <StatusTable data={statusData} />
            {statusData.status === status.SUCCESS && (
              <LoadingButton
                variant="contained"
                type="button"
                color="secondary"
                sx={{ display: 'flex', m: '1rem auto 0' }}
                onClick={handleDownloadResultFile}
                loading={currentStatus === status.PENDING2}
              >
                {t('download result')}
              </LoadingButton>
            )}
          </Box>
        )}
      </Box>
    </SubviewContainer>
  );
};

export default TaskView;
