import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddTaskForm from '../components/AddTaskForm';
import SubviewContainer from '../components/SubviewContainer';
import { createTask } from '../services/api_requests';
import { statusCodes, createNotification } from '../utils';
import taskPayload from '../helpers/pilot_payload.json';

const AddTaskView = ({ userId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleTaskSubmit = async name => {
    setIsLoading(true);

    try {
      const newTaskData = {
        ...taskPayload,
        new_task: {
          ...taskPayload.new_task,
          user_id: Number(userId),
          ...(name.trim() ? { name } : {}),
        },
      };

      const response = await createTask(newTaskData);

      if (response.status === statusCodes.HTTP_200_OK) {
        navigate(`./${response.data.id}`, { state: { name } });
      }
    } catch (error) {
      createNotification(t('common error'), 'error');

      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SubviewContainer title={t('create task')}>
      <Box sx={{ padding: '1rem 2rem 2rem' }}>
        <AddTaskForm isLoading={isLoading} onTaskAdd={handleTaskSubmit} />
      </Box>
    </SubviewContainer>
  );
};

AddTaskView.propTypes = {
  userId: PropTypes.string,
};

export default AddTaskView;
