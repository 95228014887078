import { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AddTaskForm = ({ isLoading, onTaskAdd }) => {
  const [taskName, setTaskName] = useState('');

  const { t } = useTranslation();

  const handleAddTask = () => {
    onTaskAdd(taskName);
  };

  const handleInputChange = e => {
    const { name, value } = e.currentTarget;

    switch (name) {
      case 'taskName':
        setTaskName(value);
        break;

      default:
        break;
    }
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        '& .MuiTextField-root': { m: '1rem 0', width: '100%' },
      }}
    >
      <TextField
        id="AddTaskTextInput-1"
        name="taskName"
        value={taskName}
        label={t('name')}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleInputChange}
      />
      <LoadingButton
        type="button"
        variant="contained"
        color="secondary"
        sx={{ display: 'flex', m: '0.5rem auto' }}
        onClick={handleAddTask}
        loading={isLoading}
      >
        {t('next')}
      </LoadingButton>
    </Box>
  );
};

AddTaskForm.propTypes = {
  isLoading: PropTypes.bool,
  onTaskAdd: PropTypes.func.isRequired,
};

export default AddTaskForm;
