import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const { pathname } = useLocation();

  const getFooterClassName = () => {
    let classes = 'footer';
    if (pathname === '/login') {
      classes += ' login';
    } else {
      classes += ' main';
    }
    return classes;
  };

  return (
    <footer className={getFooterClassName()}>
      <Box>&#169; Mains Lab 2020-{new Date().getFullYear()}</Box>
    </footer>
  );
};

export default Footer;
