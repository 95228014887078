import { createTheme } from '@mui/material/styles';
import { grey, red } from '@mui/material/colors';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 990,
      md: 1240,
      lg: 1520,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: grey[700],
    },
    secondary: {
      main: red[700],
    },
    common: {
      border: `1px solid ${grey[500]}`,
    },
  },
  typography: {
    fontFamily: 'var(--main-font-family-medium)',
    fontSize: 16,
    fontWeightRegular: 'var(--font-weight-regular)',
    fontWeightMedium: 'var(--font-weight-medium)',
    fontWeightBold: 'var(--font-weight-bold)',
  },
});

theme.components = {
  MuiTypography: {
    variants: [
      {
        props: { variant: 'h1' },
        style: {
          fontSize: 'var(--font-size-xl)',
          color: 'var(--color-primary-grey)',
          fontWeight: 'var(--font-weight-bold)',
        },
      },
    ],
  },

  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: 'var(--color-white)',
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottom: '2px solid var(--color-primary-grey)',
        },
        '& .MuiInputBase-input.MuiFileInput ~ span': {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    },
  },
  /*table */
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '7px 10px',
        border: theme.palette.common.border,
      },
    },
  },

  MuiButtonBase: {
    styleOverrides: {
      root: { letterSpacing: '1px' },
    },
  },
};

export default theme;
